/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import BuyOrderChart from "../../Components/ExchangeComponents/BuyOrderChart/BuyOrderChart";
import MarketChart from "../../Components/ExchangeComponents/MarketChart/MarketChart";
import MarketHistory from "../../Components/ExchangeComponents/MarketHistory/MarketHistory";
import News from "../../Components/ExchangeComponents/News/News";
import OpenOrder from "../../Components/ExchangeComponents/OpenOrder/OpenOrder";
import SearchCurrency from "../../Components/ExchangeComponents/SearchCurrency/SearchCurrency";
import SellOrderChart from "../../Components/ExchangeComponents/SellOrderChart/SellOrderChart";
import SetOrder from "../../Components/ExchangeComponents/SetOrder/SetOrder";
import Footer from "../../Components/HomeComponents/Footer/Footer";
import Navbar from "../../Components/HomeComponents/Navbar/Navbar";
import Context from "../../Context/Context";
import {
  authRequest,
  cancleOrderHandler,
  refreshToken,
  sendRequest,
  trade,
  
} from "../../Helper/helper";
import styles from "./Exchange.module.scss";

const Exchange = ({
  orderByWs,
  setOrderByWs,
  globalRes,
  ticker,
  setTicker,
  cancelOrder,
  setCancelOrder,
  balance,
  setBalance,
  marketHistory,
  setMarketHistory,
  tradeHistory,
  setTradeHistory,
  openOrder,
  setOpenOrder,
  exchangePage,
  marketPair,
  setMarkerPair,
  openOrderTotal,
  setOpenOrderTotal,
  loadOpenOrder,
  setLoadOpenOrder,
  ooData,
  setOoData

   
 // exchangePage
}) => {
  const { states } = useContext(Context);
  const [minimdata, setMinimdata] = useState();
  const [buyTrade, setBuyTrade] = useState(true);
  const [sellTrade, setSellTrade] = useState(true);
  const [tradeType, setTradeType] = useState("");
  const [buyOrderData, setBuyOrderData] = useState([]);
  const [sellOrderData, setSellOrderData] = useState([]);
  const [popup, setPopup] = useState(false);
  const [bid, setBid] = useState();
  const [order, setOrder] = useState();
  //const [ooData, setOoData] = useState([]);
  const [userBalance, setUserBalance] = useState();
  const dmode = states.dmode;
  const extheme = states.extheme;
  //const isLogin = states.isLogin;
  const isLogin = sessionStorage.getItem("isLogin") === "true" ? true : false;
 const [buyLoader, setBuyLoader] = useState(true);
 const [sellLoader, setSellLoader] = useState(true);
 const [marketHistoryLoader, setMarketHistoryLoader] = useState(true);
 const [openLoader, setOpenLoader] = useState(true);
// const [openLoader, setOpenLoader] = useState(true);
  const navigate = useNavigate();

  const setTrade = (pair) => {
    const [cs, ms] = pair.split("_");
    navigate(`/exchange/${cs}/${ms}`, { replace: true });
  };

  useEffect(() => {
    // window.scrollTo(0, 0)
    if (
      setOrderByWs &&
      setBalance &&
      setMarketHistory &&
      setTicker &&
      setCancelOrder &&
      setTradeHistory &&
     // setOpenOrder &&
      setOpenOrderTotal
    ) {
      setOrderByWs();
      setBalance([0]);
      setMarketHistory();
      setTicker();
      setCancelOrder();
      setTradeHistory("");
     // setOpenOrder();
      setOpenOrderTotal();
      /* setMarkerPair(); */
    }
  }, []);

  useEffect(() => {
    (async () => {
      setBuyLoader(true)
      setSellLoader(true)
      const res = await sendRequest(
        {
          currency: minimdata?.currency_symbol,
          market: minimdata?.market_symbol,
        },
        "buy-orders"
      );
      //res.data ? setBuyOrderData(res.data.reverse()) : setBuyOrderData([]);
      res.data ? setBuyOrderData(res.data) : setBuyOrderData([]);
      const ressell = await sendRequest(
        {
          currency: minimdata?.currency_symbol,
          market: minimdata?.market_symbol,
        },
        "sell-orders"
      );
      //ressell.data ? setSellOrderData(ressell.data.reverse()) : setSellOrderData([]);
      ressell.data ? setSellOrderData(ressell.data) : setSellOrderData([]);
      setBuyLoader(false)
      setSellLoader(false)
      res.refresh_token && refreshToken(res.refresh_token);
    })();
  }, [minimdata?.pair]);

  // useEffect(() => {
  //   if(order){
  //     trade(order, buyOrderData, setBuyOrderData, sellOrderData, setSellOrderData)
  //   }
  // }, [order])

  useEffect(() => {
    
   // console.log("orderByWs 0=>",orderByWs);
    if (orderByWs) {
     //console.log("orderByWs 1=>",orderByWs);
     if (minimdata) {
       // console.log("orderByWs 2=>",orderByWs);
        if (
          orderByWs.market.toLowerCase() === minimdata.market_symbol.toLowerCase() &&
          orderByWs.currency.toLowerCase() === minimdata.currency_symbol.toLowerCase()
        ) {
          trade(
            orderByWs,
            buyOrderData,
            setBuyOrderData,
            sellOrderData,
            setSellOrderData
          );
        }
      }
    }
  }, [orderByWs]);

  // console.log(orderByWs)

  useEffect(() => {
    
    (async () => {
     // console.log("globalRes=>",globalRes);
      minimdata && exchangePage({"currency_symbol":minimdata.currency_symbol,"market_symbol":minimdata.market_symbol})
      if (isLogin && minimdata) {
        const mres = await authRequest(
          { currency: minimdata.market_symbol },
          "balance"
        );
        const cres = await authRequest(
          { currency: minimdata.currency_symbol },
          "balance"
        );
      
        cres.refresh_token && refreshToken(cres.refresh_token);
      const newMarketBal=(minimdata.market_symbol==="INR"?parseFloat(mres.data[0].available_balance)-globalRes?.data?.lock_inr_trade:parseFloat(mres.data[0].available_balance)).toFixed(8);
     // console.log(" newMarketBal ", newMarketBal);
        setUserBalance({
         // buy_balance: parseFloat(mres.data[0].available_balance),
         buy_balance:  newMarketBal < 0 ? (0).toFixed(8) : newMarketBal,
          sell_balance: parseFloat(cres.data[0].available_balance).toFixed(8),
        });
      }
      if(!isLogin){
        setUserBalance({
          buy_balance:  0,
           sell_balance: 0,
         });
      }
    })();
  }, [minimdata?.pair,isLogin]);

  useEffect(() => {
    if (cancelOrder && minimdata) {
      if(cancelOrder?.market_symbol.toUpperCase() === minimdata?.market_symbol.toUpperCase() && cancelOrder?.currency_symbol.toUpperCase() === minimdata?.currency_symbol.toUpperCase()){
        const order = {
          market: cancelOrder.market_symbol,
          currency: cancelOrder.currency_symbol,
          price: parseFloat(cancelOrder.price),
          amount: parseFloat(cancelOrder.qty),
          buyTrade: cancelOrder.type === "Buy" ? true : false,
          sellTrade: cancelOrder.type === "Buy" ? false : true,
        };
        cancleOrderHandler(
          order,
          buyOrderData,
          setBuyOrderData,
          sellOrderData,
          setSellOrderData
        );
      }
    }
  }, [cancelOrder]);

  useMemo(() => {
    if (balance[1] && minimdata) {
      const _userBalance = userBalance;
      /* 
        _userBalance.buy_balance =
          balance[1][minimdata?.market_symbol]?.available_balance;
        _userBalance.sell_balance =
          balance[1][minimdata?.currency_symbol]?.available_balance; 
          //console.log("globalRes=>",globalRes);
        */
        if(minimdata?.market_symbol && balance[1][minimdata?.market_symbol]?.available_balance){
          _userBalance.buy_balance =   (minimdata.market_symbol==="INR"?balance[1][minimdata?.market_symbol]?.available_balance-globalRes?.data?.lock_inr_trade:balance[1][minimdata?.market_symbol]?.available_balance).toFixed(8);
          _userBalance.buy_balance= _userBalance.buy_balance <0 ? (0).toFixed(8) : _userBalance.buy_balance;
          //console.log(" _userBalance.buy_balance", _userBalance.buy_balance);
        }
        if(minimdata?.currency_symbol && balance[1][minimdata?.currency_symbol]?.available_balance){
          _userBalance.sell_balance =    (balance[1][minimdata?.currency_symbol]?.available_balance).toFixed(8);
        }
      setUserBalance(_userBalance);
    }
  }, [balance[0], minimdata,isLogin]);

  useEffect(() => {
    if (ticker && minimdata) {
      if(ticker?.market_symbol.toUpperCase() === minimdata?.market_symbol.toUpperCase() && ticker?.currency_symbol.toUpperCase() === minimdata?.currency_symbol.toUpperCase()){
        setMinimdata({
          ...minimdata,
          ...ticker,
          market_symbol: ticker.market_symbol.toUpperCase(),
          currency_symbol: ticker.currency_symbol.toUpperCase(),
        });
      }
    }
  }, [ticker]);
 

  return (
    <section className={dmode ? `${styles.exchange_l}  ligtModeExchange ExchangePage` : `${styles.exchange_l}  ligtModeExchange ExchangePage`}>
      <Navbar />
      <div className={styles.exchange_grids}>
        <SearchCurrency
          dmode={dmode}
          globalRes={globalRes}
          ticker={ticker}
          tradeChange={setTrade}
          smdata={setMinimdata}
          popup={popup}
          setPopup={setPopup}
          marketPair={marketPair}
          setMarkerPair={setMarkerPair}
          
        />
        <MarketChart
          miniData={minimdata}
          dmode={dmode}
          extheme={extheme}
          popup={popup}
          setPopup={setPopup}
          _isLogin={isLogin}
        />
        <BuyOrderChart
          bdata={buyOrderData}
          buyTrade={buyTrade}
          sellTrade={sellTrade}
          setSellTrade={setSellTrade}
          setBuyTrade={setBuyTrade}
          tradeType={tradeType}
          setTradeType={setTradeType}
          trade={minimdata}
          setBid={setBid}
          dmode={dmode}
          buyLoader={buyLoader}
          setBuyLoader={setBuyLoader}

        />
        <SetOrder
          userBalance={userBalance}
          setOrder={setOrder}
          bid={bid}
          setBid={setBid}
          buyTrade={buyTrade}
          setBuyTrade={setBuyTrade}
          sellTrade={sellTrade}
          setSellTrade={setSellTrade}
          tradeType={tradeType}
          dmode={dmode}
          tradeData={minimdata}
          _isLogin={isLogin}
          ooData={ooData}
          setOoData={setOoData}
          globalRes={globalRes}
          
        />
        <SellOrderChart
          sdata={sellOrderData}
          buyTrade={buyTrade}
          setBuyTrade={setBuyTrade}
          sellTrade={sellTrade}
          setSellTrade={setSellTrade}
          trade={minimdata}
          setBid={setBid}
          dmode={dmode}
          sellLoader={sellLoader}
          setSellLoader={setSellLoader}
        />
        <MarketHistory
          trade={minimdata}
          marketHistory={marketHistory}
          marketPair={marketPair}
          setMarkerPair={setMarkerPair}
          dmode={dmode}
        />
        <OpenOrder
          trade={minimdata}
          tradeHistory={tradeHistory}
          openOrder={openOrder}
          setOpenOrder={setOpenOrder}
          dmode={dmode}
          _isLogin={isLogin}
          marketPair={marketPair}
          setMarkerPair={setMarkerPair}
          openOrderTotal={openOrderTotal}
          setOpenOrderTotal={setOpenOrderTotal}
          loadOpenOrder={loadOpenOrder}
          setLoadOpenOrder={setLoadOpenOrder}
          ooData={ooData}
          setOoData={setOoData}
        />
        <News dmode={dmode} />
      </div>
      <Footer />
    </section>
  );
};

export default Exchange;
