import React, { useState, useEffect } from "react";
import styles from "./Withdraw.module.scss";
import QrReader from 'react-web-qr-reader';
import {
  authRequest,
  authRequestNb,
  refreshToken,
} from "../../../Helper/helper";
import swal from "sweetalert";
import { AiOutlineClose } from "react-icons/ai";
import { BiQrScan } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const Withdraw = ({ dmode , setWtstatus, coin, availableBalance , globalRes }) => {
  const [data, setData] = useState();
  const [chain, setChain] = useState();
  const [formData, setFormData] = useState({ amount: "" , destination : "" });
  const [QrRead, setQrRead] = useState(false);
  const [errormessage, setErrormessage] = useState({});
  const [feeInfo, setFeeInfo] = useState({});
  const [feeBoxV, setFeeBoxV] = useState(false);
  const [_otptext, set_otptext] = useState("Click here to send OTP");
  const [_subtext, set_subtext] = useState(`Withdraw ${coin}`);
  const navigate = useNavigate()
  

  const chainChange = async (e) => {
    setChain(e.target.value);
    formDataHandler("blockchain", e.target.value);
  };

  const formDataHandler = (key, value) => {
    let change = formData;
    //console.log("_value",value);
    if( parseFloat(value) >0){
      change[key] = value;
      errorHandler(key,"")
      if (key === "amount" && value ) {
        change[key] = value
        const _value = parseFloat(value);
        if(_value>0){
  
          const _avbal = parseFloat(availableBalance);
          const max =
            feeInfo.max_amount === "N/L" ? false : parseFloat(feeInfo.max_amount);
          if (max && max <= _avbal) {
            change[key] = _value > max ? max : _value;
          } else {
            change[key] = _value > _avbal ? _avbal : _value;
          }
          formDataHandler("total", value - feeInfo.fee);
          formDataHandler("fee", feeInfo.fee);
        }
      }

    }
    else
    {
      change[key] = value;
    }
    errorHandler(key, "");
    //console.log("key=>",key);
   // console.log("value=>",value);
    //console.log("change=>",change);
    setFormData({ ...change });
  };

  const errorHandler = (key, value) => {
    let change = errormessage;
    change[key] = value;
    setErrormessage({ ...change });
  };

  const sumbitHandler = async (e) => {
    e.preventDefault();
    set_subtext("Please Wait . . .");
    if(coin === 'INR'){
      if(formData.amount && formData.otp ){
        const res = await authRequest({
          amount : formData.amount,
          currency : coin,
          otp : formData.otp,
        } , "submit-withdraw");
        refreshToken(res.refresh_token)

        if(res.status === 200){
           swal(res.message)
           setWtstatus(false)
        }else if(res.status === 401){
          for (const keys of Object.keys(res.data)) {
            errorHandler(keys,res.data[keys])
          }
        }else{
          swal(res.message)
          if(res.refresh_token === false){
            navigate("/signin")
          }
        }
      }else{
        !formData.amount && errorHandler("amount","Please enter Amount !")
        !formData.otp && errorHandler("otp","Please enter OTP !")
      }
    }else{

    //  const validAddress = WAValidator.validate("0x5E33298a693e4CF1E05f179bFebd4d414f6A5340", 'BTC');
     // console.log("formData =>",formData)

      if(formData.amount && formData.otp && formData.address && formData.blockchain){
        const res = await authRequest({
          amount : formData.amount,
          address : formData.address,
          blockchain : formData.blockchain,
          destination_tag : formData.destination,
          currency : coin,
          otp : formData.otp,
        } , "submit-withdraw");
        refreshToken(res.refresh_token)
        if(res.status === 200){
          swal(res.message)
          setWtstatus(false)
        }else if(res.status === 401){
          for (const keys of Object.keys(res.data)) {
            errorHandler(keys,res.data[keys])
          }
        }else{
          swal(res.message)
          if(res.refresh_token === false){
            navigate("/signin")
          }
        }
      }else{
        !formData.amount && errorHandler("amount","Please enter Amount !")
        !formData.address && errorHandler("address","Please enter Address !")
        !formData.otp && errorHandler("otp","Please enter OTP !")
      }
    }
    set_subtext(`Withdraw ${coin}`);
  };

  const sendOtpRequest = async () => {
    set_otptext("Otp Sending . . .");
    const res = await authRequestNb("withdraw-otp");
    if(res.status === 200){
      set_otptext("Click here to send OTP");  
    }
    refreshToken(res.refresh_token)
    swal(res.message);
  };

  useEffect(() => {
    (async () => {
      setFeeBoxV(false);
      setFormData({amount: ""})
      setErrormessage({})
      setQrRead(false)
      
      const res = await authRequest({ currency: coin }, "get-withdraw-fees");
      setData(res);
      setChain(res.data[0].blockchain_type);
      setFormData({blockchain : res.data[0].blockchain_type , amount: ""});
      refreshToken(res.refresh_token);
    })();
  }, [coin]);

  useEffect(() => {
    data?.data.map((value) => {
      if (value.blockchain_type === chain) {
        setFeeInfo(value);
      }
    });
    setFormData({amount: ""})
    formDataHandler("blockchain", chain);
    setFeeBoxV(false);
    setErrormessage({})
    setQrRead(false)
  }, [chain]);

  return coin === "INR" ? (
    <div className={dmode ? styles.withdraw_d : styles.withdraw_l}>
      <form className='inputHandle' onSubmit={sumbitHandler}>
        <div className={`${styles.row} flex-between`}>
          <h3>{coin}</h3>
          <input
            type="number"
            name="amount"
            step="any"
            min={0}
            placeholder="Amount"
            value={formData.amount}
            onChange={(e) => formDataHandler("amount", e.target.value)}
          />
          {/* <button
            type="button"
            onClick={() => {
              formDataHandler(
                "amount",
                (feeInfo.max_amount === "N/L" ||
                  parseFloat(feeInfo.max_amount) >= parseFloat(availableBalance)) && globalRes?.data?.lock_inr < parseFloat(availableBalance)
                  ? parseFloat(availableBalance)
                  : parseFloat(feeInfo.max_amount) <
                      parseFloat(availableBalance)  ?
                      parseFloat(feeInfo.max_amount) : 0
              );
            }}
          >
            All
          </button> */}
          <button
            type="button"
            onClick={() => {
              formDataHandler(
                "amount",
                (feeInfo.max_amount === "N/L" ||
                  parseFloat(feeInfo.max_amount) >= parseFloat(availableBalance))
                  ? parseFloat(availableBalance)
                  : parseFloat(feeInfo.max_amount) <
                      parseFloat(availableBalance)  ?
                      parseFloat(feeInfo.max_amount) : 0
              );
            }}
          >
            All
          </button>
          <button type="button" onClick={() => setFeeBoxV(true)}>
            Fees
          </button>
        </div>

        {feeBoxV && (
          <div className={`${styles.wfi} flex-center column`}>
            <h2 className="flex-between">
              <span>Withdraw Fees</span>{" "}
              <span onClick={() => setFeeBoxV(false)} className="flex-center">
                <AiOutlineClose />
              </span>
            </h2>
            <div style={{ width: "100%" }} className="flex-start column">
              <div className={styles.wfi_row}>
                From : <span>{feeInfo.min_amount}</span>
              </div>
              <div className={styles.wfi_row}>
                To : <span>{feeInfo.max_amount}</span>
              </div>
              <div className={styles.wfi_row}>
                Fee : <span>{feeInfo.fee}</span>
              </div>
            </div>
          </div>
        )}

        {errormessage.amount && (
          <p className={styles.warning_note}>{errormessage.amount}</p>
        )}
        <div className={`${styles.row} flex-between`}>
          <h3>Fees</h3>
          <input
            type="number"
            name="fees"
            readOnly={true}
            value={formData.amount && feeInfo.fee}
            min={0}
            placeholder="0.000000"
          />
          <h3>{coin}</h3>
        </div>
        <div className={`${styles.row} flex-between`}>
          <h3>Total</h3>
          <input
            type="number"
            name="total"
            value={formData.amount && formData.amount - feeInfo.fee}
            readOnly={true}
            min={0}
            placeholder="0.000000"
          />
          <h3>{coin}</h3>
        </div>

        <div className={`${styles.row} flex-between`}>
          <input
            type="text"
            name="otp"
            placeholder="Enter OTP"
            onChange={(e) => formDataHandler("otp", e.target.value)}
          />
          <button
            type="button"
            onClick={sendOtpRequest}
            style={{ width: "80%" }}
          >
            {/* {"Click here to send OTP"} */}
            {_otptext}
          </button>
        </div>
        {errormessage.otp && (
          <p className={styles.warning_note}>{errormessage.otp}</p>
        )}

        {/* <button disabled={parseFloat(availableBalance) < globalRes?.data?.lock_inr} style={{opacity : parseFloat(availableBalance) < globalRes?.data?.lock_inr ? "0.5" : "1"}} type="submit">
         
          _subtext
        </button> */}
          {/* Withdraw {coin} */}
       {/*  <button disabled={feeInfo.activeStatus==="0" ? true : (parseFloat(availableBalance) < globalRes?.data?.lock_inr)} style={{opacity : parseFloat(availableBalance) < globalRes?.data?.lock_inr ? "0.5" : "1"}} type="submit">
          {feeInfo.activeStatus==="1" ? _subtext  : "Out of Service !"}
        </button> */}
         <button disabled={feeInfo.activeStatus==="0" ? true : false} style={{opacity : feeInfo.activeStatus==="0" ? "0.5" : "1"}} type="submit">
          {/* Withdraw {coin} */}
          {feeInfo.activeStatus==="1" ? _subtext  : "Out of Service !"}
        </button>
        { globalRes?.data?.lock_inr>0
        ?
        <div className={`${styles.row} flex-between1`}>
        <h6>Important Note</h6>
        <p>Minimum wallet balance is {globalRes?.data?.lock_inr}  INR.</p>
      </div> 
        :
        <></>
        }
        {/*}
         <div className={`${styles.row} flex-between1`}>
              <h6>Important Note</h6>
              <p>Minimum wallet balance is {globalRes?.data?.lock_inr}  INR.</p>
            </div> */ }
      </form>
    </div>
  ) : (
    <div className={styles.withdraw_l}>
      <div className="slectblockchian">
      {data && (
        <div className={` ${styles.dbox} borderfullforget`}>
          <select
            onChange={chainChange}
            value={chain}
            className={styles.chainOpt}
          >
            {data.data?.map((value, i) => (
              <option key={i} value={value.blockchain_type}>
                {value.blockchain_type.toUpperCase()}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>

      <form onSubmit={sumbitHandler} className='inputHandle'>
        <div className={`${styles._address} flex-between`}>
        <input
          onChange={(e) => formDataHandler("address", e.target.value)}
          defaultValue={formData.address && formData.address}
          type="text"
          name="address"
          placeholder={`${coin} Address`}
        />
        <span onClick={() => QrRead ? setQrRead(false) : setQrRead(true)} className="flex-center">{!QrRead ? <BiQrScan /> : <AiOutlineClose />}</span>
        </div>
        {QrRead && <QrReader
        onScan={(result) => {
          if (!!result) {
            formDataHandler("address",result.data)
            setQrRead(false)
          }
        }}
        onError={(error)=>{
          if (!!error) {
            window.alert(error);
            setQrRead(false);
          }
        }}
        className={styles.qrReader}
      />}
        {errormessage.address && (
          <p className={styles.warning_note}>{errormessage.address}</p>
        )}
        {coin === "XRP" && <div className={`${styles.row} flex-between`}>
        <input
            name="destination"
            step="any"
            placeholder="Destination"
            value={formData.destination}
            onChange={(e) => formDataHandler("destination", e.target.value)}
          />
        </div>}
        <div className={`${styles.row} flex-between`}>
          <h3>{coin}</h3>
          <input
            type="number"
            name="amount"
            step="any"
            min={0}
            placeholder="Amount"
            value={formData.amount}
            onChange={(e) => formDataHandler("amount", e.target.value)}
          />
          <button
            type="button"
            onClick={() => {
              formDataHandler("amount",parseFloat(availableBalance))
              // formDataHandler(
              //   "amount",
              //   feeInfo.max_amount === "N/L" ||
              //     parseFloat(feeInfo.max_amount) >= parseFloat(availableBalance)
              //     ? parseFloat(availableBalance)
              //     : parseFloat(feeInfo.max_amount) <
              //         parseFloat(availableBalance) &&
              //         parseFloat(feeInfo.max_amount)
              // );
            }}
          >
            All
          </button>
          <button type="button" onClick={() => setFeeBoxV(true)}>
            Fees
          </button>
        </div>

        {feeBoxV && (
          <div className={`${styles.wfi} flex-center column `}>
            <h2 className="flex-between">
              <span>Withdraw Fees</span>{" "}
              <span onClick={() => setFeeBoxV(false)} className="flex-center">
                <AiOutlineClose />
              </span>
            </h2>
            <div style={{ width: "100%" }} className="flex-start column widthpad">
              <div className={styles.wfi_row}>
                From : <span>{feeInfo.min_amount}</span>
              </div>
              <div className={styles.wfi_row}>
                To : <span>{feeInfo.max_amount}</span>
              </div>
              <div className={styles.wfi_row}>
                Fee : <span>{feeInfo.fee}</span>
              </div>
            </div>
          </div>
        )}

        {errormessage.amount && (
          <p className={styles.warning_note}>{errormessage.amount}</p>
        )}
        <div className={`${styles.row} flex-between`}>
          <h3>Fees</h3>
          <input
            type="number"
            name="fees"
            readOnly={true}
            value={formData.amount && feeInfo.fee}
            min={0}
            placeholder="0.000000"
          />
          <h3>{coin}</h3>
        </div>
        <div className={`${styles.row} flex-between`}>
          <h3>Total</h3>
          <input
            type="number"
            name="total"
            value={formData.amount && formData.amount - feeInfo.fee}
            readOnly={true}
            min={0}
            placeholder="0.000000"
          />
          <h3>{coin}</h3>
        </div>

        <div className={`${[styles.row , styles.otp].join(" ")} flex-between`}>
          <input
            type="text"
            name="otp"
            placeholder="Enter OTP"
            onChange={(e) => formDataHandler("otp", e.target.value)}
          />
          <button
            type="button"
            onClick={sendOtpRequest}
            style={{ width: "80%" }}
          >
            {/* {"Click here to send OTP"} */}
            {_otptext}
          </button>
        </div>
        {errormessage.otp && (
          <p className={styles.warning_note}>{errormessage.otp}</p>
        )}
        {/* `Withdraw ${coin}` */}
     
        <button disabled={feeInfo.activeStatus==="0" ? true : false} style={{opacity : feeInfo.activeStatus==="0" ? 0.7 : 1}} type="submit">{feeInfo.activeStatus==="1" ? _subtext  : "Out of Service !"}</button>
        
       
      </form>
    </div>
  );
};

export default Withdraw;
